const URLS = {
    /*CORE*/
    ROOT: "/",
    ERROR: "/404",
    ABOUT: "/about",
    CONTACT: "/contact",
    BOOK: "/book",

    /*EMPLOYEE PROFILE*/
    PROFILE: "/profiles",
    PROFILE_YASHA: "/profiles/yasha",
    PROFILE_MIA: "/profiles/mia",
    PROFILE_BROOKE: "/profiles/brooke",
    PROFILE_NICK: "/profiles/nick",

    /*SERVICES*/
    PHYSIOTHERAPY: "/services/physiotherapy",
    PHYSIO_LEAD_REHAB: "/services/physio-led-rehab",
    PROFESSIONAL_MENTORING: "/services/professional-mentoring",

    /* EXTERNAL */
    CLINIKO: "https://movewell-physiotherapy-melbourne.au2.cliniko.com/bookings",
}

export {
    URLS
}
